<template>
  <div class="empower-container">
    <div class="video">
      <video
        src="http://res.quanbangcloud.com/videos/00/00208b1e26cc4ceca4bb042e9f7f7b47.mp4"
        poster="@/assets/images/video_cover.png"
        loop="loop"
        ref="video"
        :controls="controls"
        @click="handlePlay(false)"
      ></video>
    </div>
    <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
      <img src="@/assets/images/fission/play_btn.png" alt="" />
    </div>
    <div class="introduction">
      将组织8-10人实操私房密训，从方案制定到方案审核，最后到最终方案落地，给你一个最合适的创业思路。<br />
      线上系统一对一跟进，定制化合作协议<br />方案文案参照库
    </div>
  </div>
</template>

<script>
export default {
  name: 'empowerVideo',
  data() {
    return {
      showPlayBtn: true,
      controls: false
    }
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.controls = true
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
      this.controls = false
    }
  }
}
</script>

<style lang="less" scoped>
.empower-container {
  padding-top: 84px;
  padding-bottom: 58px;
  position: relative;
  .video {
    width: 335px;
    height: 182px;
    margin: 0 auto;
    video {
      object-fit: cover;
      width: 100%;
    }
  }
  .play_btn {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 26px;
    height: 26px;
  }
  .introduction {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 19px 21px;
  }
}
</style>
