<template>
  <div class="empower-container">
    <!-- navbar -->
    <qb-navbar title="赋能计划"></qb-navbar>
    <!-- title -->
    <qb-title title="赋能计划" subtitle="Quanbang Group Product"></qb-title>
    <!-- empower-video -->
    <empower-video></empower-video>

    <!-- empower schedule -->
    <empower-schedule></empower-schedule>

    <!-- empower module -->
    <empower-module></empower-module>
    <!-- qb-application -->
    <qb-application></qb-application>
  </div>
</template>

<script>
import EmpowerVideo from './components/empower-video.vue'
import EmpowerSchedule from './components/empower-schedule.vue'
import EmpowerModule from './components/empower-module.vue'
export default {
  name: 'Empower',
  components: {
    EmpowerVideo,
    EmpowerSchedule,
    EmpowerModule
  }
}
</script>

<style lang="less" scoped></style>
