<template>
  <div class="empower-module-container">
    <h3 class="title">赋能计划服务模块</h3>
    <div class="line"></div>
    <div class="subtitle">六个月陪跑 制定最详细的方案</div>
    <ul class="content">
      <li v-for="item in moduleList" :key="item.id">
        <div class="mask">{{ item.id }}</div>
        <h3 class="title-1">{{ item.title }}</h3>
        <div class="content-1">{{ item.subTitle }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EmpowerModule',
  data() {
    return {
      moduleList: [
        {
          id: 1,
          title: '资源型股东',
          subTitle: '大客户变成不花钱的业务员'
        },
        {
          id: 2,
          title: '消费型股东',
          subTitle: '捆绑优质客户成为股东，分享裂变'
        },
        {
          id: 3,
          title: '拓客方案',
          subTitle: '单场50-500有质量新客户流量'
        },
        {
          id: 4,
          title: '精英入股计划',
          subTitle: '捆绑核心员工，稳定内部团队'
        },
        {
          id: 5,
          title: '拓站系统上线',
          subTitle: '线上实现自动引流、锁定、分佣'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.empower-module-container {
  padding-top: 106px;
  .title {
    font-size: 18px;
    font-weight: 700;
    color: #010343;
    line-height: 25px;
    margin-left: 25px;
  }
  .line {
    width: 375px;
    border-bottom: 1px solid #010343;
    margin: 11px 0 11px;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 700;
    color: #010343;
    line-height: 22px;
    margin-left: 25px;
    margin-bottom: 34px;
  }
  .content {
    li {
      position: relative;
      width: 335px;
      height: 90px;
      background: #f7f9fd;
      margin: 0 auto;
      padding-left: 64px;
      padding-top: 12px;
      margin-top: 22px;
    }
    .mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 46px;
      height: 46px;
      line-height: 46px;
      background: #010343;
      border-radius: 0px 0px 34px 0px;
      color: #fff;
      padding-left: 16px;
      font-size: 18px;
    }
    .title-1 {
      font-size: 18px;
      font-weight: 700;
      color: #010343;
    }
    .content-1 {
      font-size: 18px;
      font-weight: 500;
      color: #020046;
      margin-top: 20px;
    }
  }
}
</style>
