<template>
  <div class="empower-schedule-container">
    <h3 class="title">赋能方案私教课课程安排</h3>
    <div class="line"></div>
    <van-image
      :src="require('@/assets/images/empower/empower-schedule.png')"
      class="empower-schedule"
    ></van-image>
  </div>
</template>

<script>
export default {
  name: 'EmpowerSchedule'
}
</script>

<style lang="less" scoped>
.empower-schedule-container {
  .title {
    font-size: 18px;
    font-weight: 700;
    color: #010343;
    line-height: 25px;
    text-align: center;
  }
  .line {
    width: 258px;
    border-bottom: 2px solid #010343;
    margin: 0 auto 33px;
  }
  .empower-schedule {
    display: block;
    width: 330px;
    height: 696px;
    margin: 0 auto;
  }
}
</style>
